.produk {
  width: 100%;
  margin-top: 5%;
  .container-produk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .header-produk {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      font-family: "DM Sans";
      color: #221355;
    }
    .carousel-highlight {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        width: 98%;
      }
      .slick-slider {
        width: 90%;
        //margin: 5px;
        @media (max-width: 768px) {
          width: 98%;
        }
        .kartugambar {
          width: 100%;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
}
.cardhome-container {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
