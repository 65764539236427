@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-promo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #221355;
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
}
.dividera {
  width: 100%;
  background-color: #221355;
  height: 100px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    font-family: "shape divider from ShapeDividers.com";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100% 92px;
    background-position: 50% 0%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
  }

  @media (min-width: 768px) {
    .shapedividers_com-5429::before {
      background-size: 100% 92px;
      background-position: 50% 0%;
    }
  }

  @media (min-width: 1025px) {
    .shapedividers_com-5429::before {
      bottom: -0.1vw;
      left: -0.1vw;
      right: -0.1vw;
      top: -0.1vw;
      background-size: 100% 180px;
      background-position: 50% 0%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
    }
  }
  @media (min-width: 2100px) {
    .shapedividers_com-5429::before {
      background-size: 100% calc(2vw + 180px);
    }
  }
}
.dividerb {
  width: 100%;
  background-color: #221355;
  height: 100px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    font-family: "shape divider from ShapeDividers.com";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100% 90px;
    background-position: 50% 100%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>');
  }

  @media (min-width: 768px) {
    .shapedividers_com-1322::before {
      background-size: 100% 90px;
      background-position: 50% 100%;
    }
  }

  @media (min-width: 1025px) {
    .shapedividers_com-1322::before {
      bottom: -0.1vw;
      left: -0.1vw;
      right: -0.1vw;
      top: -0.1vw;
      background-size: 100% 145px;
      background-position: 50% 100%;
    }
  }
  @media (min-width: 2100px) {
    .shapedividers_com-1322::before {
      background-size: 100% calc(2vw + 145px);
    }
  }
}
.container-listpromo {
  background-color: #221355;
  padding-bottom: 50px;
  margin-top: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 1;
  justify-content: flex-end; /* Added justify-content */
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: -15px;
  }

  .containergambar {
    order: 1;
    width: 100%;
    text-align: right;
    @media (max-width: 768px) {
      margin-top: 25px;
      order: 1;
      width: 100%;
      text-align: center;
    }

    .penawarangambar {
      width: 500px;
      height: 650px;
      align-self: flex-end;
      box-shadow: 0 0 10px 0;
      border-radius: 5px;
      @media (max-width: 768px) {
        width: 90%;
        height: 100%;
      }
    }
  }

  .containercontent {
    order: 2;
    width: 100%;
    margin-left: 100px;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-left: 25px;
      width: 90%;
      order: 2;
      margin-top: 30px;
    }
  }
  .judul-promo {
    width: 70%;
    font-weight: 500;
    font-size: 40px;
    line-height: 1em;
    color: #ffffff;
    font-family: "Poppins";
    @media (max-width: 768px) {
      width: 100%;
      font-size: 25px;
      margin-bottom: 17px;
    }
  }
  .subjudul-promo {
    width: 70%;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    letter-spacing: 0.5em;
    color: #d6b17a;
    font-family: "Poppins";
    text-shadow: 1px 1px 1px rgba(214, 177, 122, 0.7);
  }
  .disclaimer {
    margin-top: 25px;
    font-size: 13px;
  }
  .pointpenawaran {
    flex-direction: row;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 25px;
      width: 100%;
    }
    .penawaranpoin {
      margin-top: 0px;
      color: #ffffff;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .button-wa-promo {
    padding-top: 15px;
    padding-bottom: 10px;
    .whatsapp {
      box-shadow: 0 0 2px 0 rgb(61, 61, 61);
      font-size: 12px;
      cursor: pointer;
      background-color: #25d366;
      padding: 15px 30px;
      border: none;
      color: white;
      border-radius: 5px;
      &:hover {
        color: #25d366;
        background-color: white;
      }
    }
  }
}
