@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.benefit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: #221355;
  margin-top: 3%;
  .container-benefit {
    width: 80%;
    @media (max-width: 768px) {
      width: 90%;
    }
    .header-benefit {
      text-align: center;
      @media (max-width: 768px) {
        text-align: left;
      }
      .judul-benefit {
        font-family: "DM Serif Display";
        color: white;
        font-size: 42px;
        letter-spacing: 2px;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
    }
    .list-benefit {
      @media (max-width: 768px) {
        width: 100%;
      }
      .list {
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        ul {
          padding-inline-start: 0px;
          display: flex;
          padding-left: 30px;
          padding-right: 30px;
          border-radius: 10px;
          @media (max-width: 768px) {
            flex-direction: column;
            padding: 0px;
            width: 100%;
          }
        }
        ul li {
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          list-style-type: none;
          padding: 20px 50px;
          text-align: center;
          @media (max-width: 768px) {
            padding: 15px 20px 10px 10px;
            flex-direction: row;
            text-align: left;
            justify-content: left;
          }
        }
      }
    }
  }
}
.dividera {
  width: 100%;
  background-color: #221355;
  height: 100px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    font-family: "shape divider from ShapeDividers.com";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100% 92px;
    background-position: 50% 0%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
  }

  @media (min-width: 768px) {
    .shapedividers_com-5429::before {
      background-size: 100% 92px;
      background-position: 50% 0%;
    }
  }

  @media (min-width: 1025px) {
    .shapedividers_com-5429::before {
      bottom: -0.1vw;
      left: -0.1vw;
      right: -0.1vw;
      top: -0.1vw;
      background-size: 100% 180px;
      background-position: 50% 0%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
    }
  }
  @media (min-width: 2100px) {
    .shapedividers_com-5429::before {
      background-size: 100% calc(2vw + 180px);
    }
  }
}
.dividerb {
  width: 100%;
  background-color: #221355;
  height: 100px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    font-family: "shape divider from ShapeDividers.com";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100% 90px;
    background-position: 50% 100%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>');
  }

  @media (min-width: 768px) {
    .shapedividers_com-1322::before {
      background-size: 100% 90px;
      background-position: 50% 100%;
    }
  }

  @media (min-width: 1025px) {
    .shapedividers_com-1322::before {
      bottom: -0.1vw;
      left: -0.1vw;
      right: -0.1vw;
      top: -0.1vw;
      background-size: 100% 145px;
      background-position: 50% 100%;
    }
  }
  @media (min-width: 2100px) {
    .shapedividers_com-1322::before {
      background-size: 100% calc(2vw + 145px);
    }
  }
}
