.lokasi {
  .judul {
    font-size: 65px;
    font-family: "Poppins", sans-serif;
    color: #15283b;
    font-weight: 500;
    margin-top: 50px;
    text-align: center;
  }
  .maps-lokasi {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: hidden;
    }
    .img-lokasi-dekstop {
      width: 80%;
      height: 476px;
      @media (max-width: 768px) {
        visibility: hidden;
        width: 0%;
        height: 0%;
      }
    }
    .img-lokasi-mobile {
      visibility: hidden;
      width: 0%;
      @media (max-width: 768px) {
        visibility: visible;
        width: 98%;
        height: 100%;
      }
    }
  }
  .benefit-container1 {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .benefit-point2 {
      color: #535353;
      padding-right: 45px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding-right: 0;
        padding-left: 25px;
      }
      .benefit-title3 {
        font-family: "Robbotto", Sans-serif;
        font-size: 40px;
        font-weight: 600;
        font-size: 30px;
        padding: 10px 25px 25px 0;
      }
      .benefit {
        font-size: 18px;
      }
    }
  }
}
