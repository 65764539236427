.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 0 10px 0 rgb(49, 49, 49);
  background-color: rgb(255, 255, 255);
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  .container-navbar {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    .logo-navbar {
      width: 100%;
      @media (max-width: 768px) {
        text-align: center;
      }
      img {
        width: 30%;
        @media (max-width: 768px) {
          width: 90%;
          text-align: center;
        }
      }
    }
    .buttonwa {
      width: 100%;
      text-align: right;
      @media (max-width: 768px) {
        visibility: hidden;
        width: 0px;
      }
      .whatsapp {
        cursor: pointer;
        padding: 10px 30px;
        border-color: #298347;
        background-color: #298347;
        color: white;
        border-radius: 5px;
        font-size: 18px;
        &:hover {
          background-color: white;
          color: #298347;
        }
        @media (max-width: 768px) {
          visibility: hidden;
          padding: 0px;
        }
      }
    }
  }
}
